<script>
import { signUpNewsletter } from '../api/newsletter';
import { emailRegex } from '../constants';

export default {
  name: 'CpSubscribeToNewsletterInFooter',
  components: {
    CpButton: require('../button').default,
    CpTextInput: require('../inputs/text').default,
    CpToasted: require('../toasted').default,
  },
  directives: {
    margin: require('../directives/margins').margin,
    marginTop: require('../directives/margins').marginTop,
    marginBottom: require('../directives/margins').marginBottom,
    marginRight: require('../directives/margins').marginRight,
  },
  data() {
    return {
      email: '',
      loading: false,
      toasted: [],
      errorMessage: '',
    };
  },
  methods: {
    async handleSubscribe() {
      this.errorMessage = '';
      this.loading = true;
      if (this.isSubmitAllowed) {
        await signUpNewsletter({ email: this.email })
          .then(() => {
            this.email = '';
            this.createAlert({ message: 'Successfully subscribed to Daily Newsletter!' });
          })
          .catch(error => {
            console.warn(error);
            this.createAlert({ message: 'An error has occurred, try again!', theme: 'danger' });
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
        this.errorMessage = 'A valid email address is required';
      }
    },
    createAlert({ message, theme = 'success', duration = 2000 }) {
      this.toasted.push({ key: `alert-${new Date().toString()}`, message, theme, duration });
    },
  },
  computed: {
    isSubmitAllowed() {
      return emailRegex.test(this.email) && this.email;
    },
  },
};
</script>

<template>
  <div class="container">
    <div class="alerts">
      <CpToasted v-for="toast in toasted" :key="toast.key" :message="toast.message" :theme="toast.theme" :duration="toast.duration" />
    </div>
    <h3 class="site-footer__nav-heading">Subscribe to Daily News</h3>
    <p class="daily-news-text" v-margin-top="0">
      Sign up to receive our daily newsletter of trending headlines, Crowdpac campaigns, lobbies, petitions, and more.
    </p>
    <div class="inputContainer">
      <input :class="{ 'text-input': true, 'input-error': errorMessage }" v-model="email" placeholder="Email" v-margin-bottom="0" v-margin-right="0.5" />
      <span class="buttonContainer">
        <button @click.prevent="handleSubscribe" :disabled="loading">
          <img v-if="loading" src="https://assets.staging.crowdpac.com/v2/demo/all/img/spin.gif" alt="Loading..." />
          <span v-else>Subscribe</span>
        </button>
      </span>
    </div>
    <p v-if="errorMessage" class="footnote error-color"><i class="fas fa-exclamation-circle error-color"></i>&nbsp;{{ errorMessage }}</p>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 360px;
  margin: 0 16px;
}

.alerts {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.daily-news-text {
  color: #444;
}

.inputContainer {
  display: flex;
  align-items: center;
}

.inputContainer .text-input {
  flex: 2;
  border: 1px solid #d6d6d6;
  border-bottom: 3px solid var(--neutral4);
  border-radius: 5px 5px 0 0;
  background: transparent;
  color: black;
  flex-grow: 1;
  font: inherit;
  width: auto;
  padding: 0.3em 0.5em;
}

.inputContainer .text-input:focus {
  border-color: var(--black);
}

.inputContainer .input-error {
  border-color: var(--error);
}

.inputContainer .buttonContainer {
  background-color: var(--black);
  padding: 4px;
}

.buttonContainer button {
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  color: var(---white);
  font-family: var(--sans-font);
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.3;
  margin: 0;
  transition: background-color 0.1s, border-color 0.1s, box-shadow 0.1s, color 0.1s, opacity 0.2s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.3em 1em;
  background: var(--button-color);
}

.buttonContainer button:disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

@media screen and (max-width: 768px) {
  .container {
    margin: 16px 0;
    max-width: 100%;
  }
}
</style>
