import Vue from 'vue';
import device from 'vue-device-detector';
Vue.use(device);

// These components are available anywhere on any page
// instead of as descendants of a single Vue root.
// Just make sure they have the `COMPONENT_CLASS` below.
// NOTE: Each instance will be its own `this.$root`.

import CpSiteSearch from './site-header/search';
import CpSiteSearchToggleButton from './site-header/search/toggle-button';
import CpSiteClosureBanner from './site-header/site-closure-banner';
import CpSiteChatNotification from './site-header/chat-notification';
import CpSidebarMenuGlobal from './sidebar-menu-global';
import CpHeaderMenuTooltip from './site-header/header-menu-tooltip';
import CpHeader from './site-header/header';
import CpShowImageInCard from './components/ShowImageInCard.vue';
import CpSubscribeToNewsletterInFooter from './components/SubscribeToNewsletterInFooter.vue';

Vue.component('cp-site-search', CpSiteSearch);
Vue.component('cp-site-search-toggle-button', CpSiteSearchToggleButton);
Vue.component('cp-site-closure-banner', CpSiteClosureBanner);
Vue.component('cp-site-chat-notification', CpSiteChatNotification);
Vue.component('cp-sidebar-menu-global', CpSidebarMenuGlobal);
Vue.component('cp-header-menu-tooltip', CpHeaderMenuTooltip);
Vue.component('cp-header', CpHeader);
Vue.component('cp-show-image-in-card', CpShowImageInCard);
Vue.component('cp-subscribe-to-newsletter-in-footer', CpSubscribeToNewsletterInFooter);

const COMPONENT_CLASS = 'js-vue-component';

export default function instantiateGlobalComponents(root = document) {
  const componentElements = [...root.querySelectorAll(`.${COMPONENT_CLASS}`)];

  return componentElements.map(element => {
    return new Vue({ el: element });
  });
}
