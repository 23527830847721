// See https://webpack.js.org/guides/public-path/#on-the-fly
__webpack_public_path__ = document.querySelector('meta[name="webpack-public-path"]').content;

import '@babel/polyfill';
import 'dom4';

import * as Sentry from '@sentry/vue';
import axios from 'axios';
import Vue from 'vue';
import instantiateGlobalComponents from './instantiate-global-components';
import preventExcessiveHoverStates from './lib/prevent-excessive-hover-states';
import './legacy';
import LaravelMixHack from './LaravelMixHack';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { useUserStore } from '../../store/useUserStore';

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.use(pinia);

Vue.prototype.windowObject = window;
axios.defaults.timeout = 240000;

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 404 && error.response.data.error === 'failed_to_find_permalink_post') {
      console.error('404 Error:', error);
      window.location.href = '/notFoundPage';
    }
    return Promise.reject(error);
  },
);

const stateAtLoad = (function () {
  const stateHolder = document.getElementById('state-at-load');
  return stateHolder ? JSON.parse(stateHolder.value) : {};
})();

const tokenHolder = document.getElementById('csrf-token');
// Only pages based on the "_layouts.default" view will have this.
if (tokenHolder) {
  stateAtLoad.csrfToken = tokenHolder.value;
  axios.defaults.headers['X-CSRF-TOKEN'] = tokenHolder.value;
}

(async function () {
  let pageRootMixin = Promise.resolve({});

  if (stateAtLoad.deprecatedVueRootMixin) {
    const pageRootMixinImporter = {
      campaignEditor: () => import('./user-campaign/creation'),
      electionDetail: () => import('./election-page'),
      userProfile: () => import('./user-profile'),
      userSettings: () => import('./user-settings'),
      userConnections: () => import('./user-connections'),
      membersConnections: () => import('./groups/members-connections'),
      searchRepresentative: () => import('./lobbying'),
      newsDetails: () => import('./news'),
      blogDetails: () => import('./blogs'),
      polls: () => import('./polls'),
      groups: () => import('./groups'),
      events: () => import('./events'),
      userSettingsNotifications: () => import('./user-settings/emails/'),
      officials: () => import('./officials'),
    }[stateAtLoad.deprecatedVueRootMixin];

    // Page-level root mixins are deprecated. Build a component!
    pageRootMixin = (await pageRootMixinImporter()).default;
  }

  const vueRootElement = document.getElementById('vue-root');
  if (vueRootElement) {
    const rootVueInstance = new Vue({
      el: document.getElementById('vue-root'),
      components: {
        LaravelMixHack,
        CpActionPage: () => import('./action-page/action-page'),
        CpActionPageEditor: () => import('./user-campaign/action-page/editor'),
        CpActionPagesIndex: () => import('./user-campaign/action-page/index'),
        CpCampaignButtonBuilder: () => import('./user-campaign/buttons/builder'),
        CpCampaignContributions: () => import('./user-campaign/campaign-contributions/contributions'),
        CpCampaignDashboard: () => import('./user-campaign/campaign-dashboard/dashboard'),
        CpCampaignFinancesForm: () => import('./user-campaign/finances/form'),
        CpCampaignFinancesSuccess: () => import('./user-campaign/finances/success'),
        CpCampaignSettingsPage: () => import('./user-campaign/settings/settings'),
        CpCampaignUpdates: () => import('./user-campaign/campaign-updates/updates'),
        CpCampaignUpdatesCreate: () => import('./user-campaign/campaign-updates/create-update'),
        CpCandidateDetail: () => import('./candidates/detail'),
        CpCreateCampaign: () => import('./marketing/create-campaign/create-campaign'),
        CpExploreCampaignsForm: () => import('./explore-campaigns/form'),
        CpMarketingPage: () => import('./marketing/pages/detail'),
        CpOrganizationDetail: () => import('./organizations/detail'),
        CpPricingPage: () => import('./marketing/pricing/pricing-page'),
        CpResultsPage: () => import('./surveys/results/results-page'),
        CpShutdownInfoPage: () => import('./shutdown-info-page'),
        CpStyleGuide: () => import('./style-guide/index'),
        CpSurveyPage: () => import('./surveys/quiz/survey-page'),
        CpUserContributionHistory: () => import('./user-contributions/contribution-history'),
        CpUserPaymentMethod: () => import('./user-contributions/payment-method'),
        CpUserRecurringDonations: () => import('./user-contributions/recurring-donations'),
        CpRallyRegister: () => import('./rallies/register'),
        CpPetitionForm: () => import('./petitions/form'),
        CpBlogForm: () => import('./blogs/form'),
        CpBlogDetails: () => import('./blogs/details'),
        CpLivestreamView: () => import('./livestream/view'),
        CpLivestreamStart: () => import('./livestream/start'),
        CpJoinEventLivestream: () => import('./livestream/join-event'),
        CpPetitionPage: () => import('./petitions/page'),
        CpRallyRegisterGlobal: () => import('./rallies/register-global'),
        CpRalliesTable: () => import('./rallies/rallies-table'),
        CpRallyQuestions: () => import('./rallies/questions'),
        CpHomeRallyBanner: () => import('./rallies/home-rally-banner'),
        CpTvPage: () => import('./tv/page'),
        CpPetitionsList: () => import('./petitions/list'),
        CpSocialFeedUserHome: () => import('./social-feed/user-home'),
        CpSocialShareDialog: () => import('./dialogs/social-share'),
        CpSocialShareDialogNew: () => import('./dialogs/social-share-new'),
        CpButton: () => import('./button'),
        CpButtonLink: () => import('./button-link'),
        CpPetition: () => import('./petitions/petition'),
        CpPetitionClose: () => import('./petitions/close'),
        CpSocialFeedPetitions: () => import('./social-feed/petitions'),
        CpPetitionSign: () => import('./petitions/sign'),
        VueSimpleSpinner: () => import('./vue-simple-spinner'),
        CpFollowersListItem: () => import('./user-connections/list-item'),
        CpUserConnections: () => import('./user-connections/connections'),
        CpFollowersButton: () => import('./user-profile/buttonProfile'),
        CpProfileActivity: () => import('./user-profile/ProfileActivity'),
        CpChatMessageButton: () => import('./user-profile/chatMessageButton'),
        CpProfileDialog: () => import('./dialogs/user-profile'),
        CpTagIcon: () => import('./tags/icon'),
        CpChat: () => import('./chats/chat'),
        CpUserInvite: () => import('./user-invite/'),
        CpBlogUpdate: () => import('./blogs/update'),
        CpNewsDetails: () => import('./news/details'),
        CpRelatedPosts: () => import('./stickiness-containers/related-posts'),
        CpSuggestedMembers: () => import('./stickiness-containers/suggested-members'),
        CpSearchRepresentative: () => import('./lobbying/search-representative'),
        CpChatMessage: () => import('./petitions/chat-message'),
        CpFollow: () => import('./petitions/follow'),
        CpProfileDialogEmoji: () => import('./dialogs/user-profile-emoji'),
        CpRallyDetails: () => import('./rallies/rally-details'),
        CpPolls: () => import('./polls/list'),
        CpPollCreate: () => import('./polls/create'),
        CpPollDetails: () => import('./polls/details'),
        CpPollUpdate: () => import('./polls/update'),
        CpPoliticalActivism: () => import('./social-feed/political-activism'),
        CpGroupForm: () => import('./groups/forms/form'),
        CpGroupDetails: () => import('./groups/details'),
        CpMembersConnections: () => import('./groups/members-connections/connections'),
        CpMembersListItem: () => import('./groups/members-connections/list-item'),
        CpGroupStatus: () => import('./groups/group-status'),
        CpGroupSettings: () => import('./groups/group-settings.vue'),
        CpEventCreate: () => import('./events/create/form'),
        CpEventDetails: () => import('./events/details'),
        CpUserDeleteAccount: () => import('./user-delete-account/user-delete-account.vue'),
        CpUserDeletedAccountSuccesfully: () => import('./user-delete-account/user-deleted-account-succesfully.vue'),
        CpNotificationList: () => import('./notifications/notificationList.vue'),
        CpMyBlogs: () => import('./blogs/my-blogs.vue'),
        CpMyEvents: () => import('./events/my-events.vue'),
        CpMyPetitions: () => import('./petitions/my-petitions.vue'),
        CpMyGroups: () => import('./groups/my-groups.vue'),
        CpSignInfo: () => import('./auth-form/sign-info.vue'),
        CpAuthForm: () => import('./auth-form/index.vue'),
        CpUserHandleNotifications: () => import('./user-settings/emails/userHandleNotifications.vue'),
        CpCommonUseDialog: () => import('./dialogs/CommonUse'),
      },

      mixins: [
        require('./mixins/auth').default,
        require('./mixins/breakpoints').default,
        require('./mixins/location').default,
        require('./mixins/cloudinary-image').default,
        require('./mixins/user-subscriptions').default,
        require('./tooltip').default.rootMixin, // TODO: Is there a more idomatic way to expose this?
        pageRootMixin,
      ],

      data: stateAtLoad,
      pinia,
    });

    if (rootVueInstance.$el.dataset) {
      rootVueInstance.$el.dataset.loaded = true;
    }

    window.ROOT_VUE_INSTANCE = rootVueInstance;

    if (process.env.MIX_APP_ENV !== 'local' && process.env.MIX_SENTRY_LARAVEL_DSN) {
      Sentry.init({
        Vue,
        dsn: process.env.MIX_SENTRY_LARAVEL_DSN,
        environment: process.env.MIX_APP_ENV,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        tracesSampleRate: 0.2,
        tracePropagationTargets: ['localhost', /crowdpac\.com/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  }

  window.VUE_COMPONENTS = instantiateGlobalComponents();
})();

preventExcessiveHoverStates();
