<script>
export default {
  name: 'CpCommonUseDialog',
  components: {
    CpButton: require('../button').default,
    CpDialog: require('../dialog').default,
  },
  directives: {
    margin: require('../directives/margins').margin,
    marginLeft: require('../directives/margins').marginLeft,
    marginRight: require('../directives/margins').marginRight,
    marginBottom: require('../directives/margins').marginBottom,
  },
  props: {
    handleDialog: Function,
  },
  methods: {
    navigateTo(path) {
      window.location.href = path;
      this.handleDialog();
    },
  },
};
</script>
<template>
  <CpDialog>
    <div class="commonUse-container">
      <i @click.prevent="handleDialog" class="fal fa-times fa-1-5x primary-color close-icon"></i>
      <div class="commonUse-dataContainer">
        <div class="commonUse-titleContainer">
          <img src="https://assets.staging.crowdpac.com/v2/demo/all/img/crowdpac-logo-orange.svg" class="commonUse-logo" v-margin-right="0.5" alt="Logo" />
          <h2 class="black-color commonUse-headline">Politics Made Simple</h2>
        </div>
        <h3 class="commonUse-headline white-color text-center commonUse-subtitleContainer">Fueling the Movement, One Campaign at a Time</h3>
        <img src="https://assets.staging.crowdpac.com/v2/demo/all/img/commonUseImg.png" />
        <div class="commonUse-itemContainer">
          <h4 class="commonUse-H2 primary-color">How it works:</h4>
          <div class="commonUse-item">
            <i class="fal fa-podium fa-1-5x primary-color" v-margin-right="1"></i>
            <p class="commonUse-subheadline1 black-color">Start or Join a Campaign</p>
          </div>
          <div class="commonUse-item">
            <i class="fal fa-rss-square fa-1-5x primary-color" v-margin-right="1"></i>
            <p class="commonUse-subheadline1 black-color">Stay Informed</p>
          </div>
          <div class="commonUse-item">
            <i class="fal fa-user-friends fa-1-5x primary-color" v-margin-right="1"></i>
            <p class="commonUse-subheadline1 black-color">Participate in the Community</p>
          </div>
          <div class="commonUse-item">
            <i class="fal fa-flag-usa fa-1-5x primary-color" v-margin-right="1"></i>
            <p class="commonUse-subheadline1 black-color">Hold Elected Officials Accountable</p>
          </div>
          <div class="commonUse-item">
            <i class="fal fa-video fa-1-5x primary-color" v-margin-right="1"></i>
            <p class="commonUse-subheadline1 black-color">Rally Your Friends</p>
          </div>
          <div class="commonUse-item">
            <p class="commonUse-subheadline1 black-color" v-margin-left="2">And much more...</p>
          </div>
        </div>
        <div class="commonUse-btnContainer">
          <button @click.prevent="navigateTo('/user/login?is_create=true')">Sign Up</button>
          <button @click.prevent="navigateTo('/campaigns/learn')">Launch Your Campaign</button>
        </div>
        <div class="commonUse-lastBtnContainer">
          <button @click.prevent="navigateTo('/user/login')" noborder>Log in</button>
          <button @click.prevent="navigateTo('/campaigns/learn')" noborder>Learn more...</button>
        </div>
      </div>
    </div>
  </CpDialog>
</template>
<style scoped>
.commonUse-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid var(--black);
  position: relative;
  max-width: 76ch;
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 25px;
  cursor: pointer;
}

.commonUse-dataContainer {
  display: flex;
  flex-direction: column;
}

.commonUse-titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  height: 50px;
  padding: 30px 80px 0;
}

.commonUse-logo {
  width: 250px;
}

.commonUse-subtitleContainer {
  background-color: var(--primary);
  margin: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.commonUse-itemContainer {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}

.commonUse-titleContainer h2,
.commonUse-itemContainer h4 {
  margin: 12px 0;
}

.commonUse-itemContainer h4 {
  text-decoration: underline;
}

.commonUse-item {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.commonUse-item p {
  margin: 10px 0;
}

.commonUse-btnContainer,
.commonUse-lastBtnContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.commonUse-btnContainer {
  padding: 30px 0 10px;
}

.commonUse-lastBtnContainer {
  padding: 10px 0 30px;
}

.commonUse-btnContainer button,
.commonUse-lastBtnContainer button {
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--sans-font);
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.3;
  margin: 0;
  transition: background-color 0.1s, border-color 0.1s, box-shadow 0.1s, color 0.1s, opacity 0.2s;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  padding: 0.6em 1em;
}

.commonUse-btnContainer button:first-child {
  background-color: var(--button-color);
  border-color: transparent;
  border: none;
  color: var(--button-background);
  box-shadow: none;
  text-shadow: none;
}

.commonUse-btnContainer button:nth-child(2) {
  background-color: var(--button-background);
  border: 1px solid;
  color: var(--button-color);
  box-shadow: 0 0 0 -15em inset;
}

.commonUse-lastBtnContainer button {
  background-color: var(--button-background);
  border: none;
  color: var(--button-color);
  box-shadow: none;
  text-shadow: none;
}

.commonUse-headline {
  font-family: 'Proxima-Nova', Helvetica, sans-serif;
  font-size: 25px;
  font-weight: bold;
}

.commonUse-H2 {
  font-family: 'Proxima-Nova', Helvetica, sans-serif;
  font-size: 31px;
  font-weight: bold;
}

.commonUse-subheadline1 {
  font-family: 'Proxima-Nova', Helvetica, sans-serif;
  font-size: 20px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .commonUse-titleContainer,
  .commonUse-btnContainer,
  .commonUse-lastBtnContainer {
    flex-direction: column;
  }
  .commonUse-titleContainer {
    padding-bottom: 10px;
  }
  .commonUse-itemContainer {
    padding: 0 10px;
  }
  .commonUse-btnContainer,
  .commonUse-lastBtnContainer {
    padding-right: 10px;
    padding-left: 10px;
  }
  .commonUse-btnContainer button,
  .commonUse-lastBtnContainer button {
    width: 100%;
  }
  .commonUse-btnContainer button:first-child {
    margin-bottom: 20px;
  }
  .commonUse-headline {
    font-size: 17px;
  }
  .commonUse-H2 {
    font-size: 22px;
  }
  .commonUse-subheadline1 {
    font-size: 15px;
  }
}

@media screen and (max-width: 400px) {
  .commonUse-logo {
    width: 150px;
  }
}
</style>
